<template>
  <div class="booking-page">
    <div class="inner-broadcast w-100" :class="isThemeHeader === 'true' ? 'bg-dark' : 'bg-white'">
      <SpinnerLoader :loading="isPartialUpdate" :float-in-corner="true" />
      <div class="p-3 d-flex flex-wrap gap-2 w-100" style="height: 96px">
        <div class="d-flex gap-3 w-100">
          <MultiSelect
            v-model="selects.channels"
            style="width: 250px"
            class="multiselect-sm"
            :options="channel"
            :placeholder="$t('channelModal.channel')"
            label="name"
            track-by="id"
            :allow-empty="false"
            @input="selectChannel"
          ></MultiSelect>

          <datepicker-wrapper v-show="!isSelectChannel" id="booking-channel-datepicker" v-model="selects.day" style="width: 250px" size="sm" required />

          <MultiSelect
            v-show="!isSelectChannel"
            v-model="selects.measurements"
            style="width: 250px"
            class="multiselect-sm"
            :options="measurementsList"
            :placeholder="$t('channelModal.measurements')"
            label="name"
            track-by="id"
          ></MultiSelect>
          <MultiSelect
            v-show="!isSelectChannel"
            v-model="selects.target"
            style="width: 250px"
            class="multiselect-sm"
            :options="targetList"
            :placeholder="$t('channelModal.target')"
            label="name"
            track-by="id"
            :disabled="!(selects.measurements && selects.measurements.id)"
            @input="updateQuery()"
          ></MultiSelect>
          <MultiSelect
            v-show="!isSelectChannel"
            v-model="selects.commercial"
            style="width: 250px"
            class="multiselect-sm"
            :options="commercialType"
            :allow-empty="false"
            :placeholder="$t('table.selectCommercialType')"
            label="name"
            track-by="id"
          ></MultiSelect>
          <!-- <MultiSelect
            v-show="!isSelectChannel"
            v-model="selects.block"
            style="width: 250px"
            class="multiselect-sm"
            :options="blockTypes"
            :allow-empty="false"
            :placeholder="$t('table.selectBlockType')"
            label="name"
            track-by="id"
          ></MultiSelect> -->
        </div>
        <div class="d-flex gap-3 w-100">
          <MultiSelect
            v-show="!isSelectChannel"
            v-model="selects.agency"
            class="multiselect-sm"
            style="width: 250px"
            :options="agenciesListWithAll"
            :placeholder="$t('table.selectAgency')"
            label="name"
            track-by="id"
            @input="selectAgency"
          ></MultiSelect>
          <MultiSelect
            v-show="!isSelectChannel"
            v-model="selects.advertiser"
            class="multiselect-sm"
            style="width: 250px"
            :options="advertisersListWithAll"
            :placeholder="$t('table.selectAdvertiser')"
            label="name"
            track-by="id"
            :disabled="!(selects.agency && selects.agency.id)"
            @input="selectAdvertiser"
          ></MultiSelect>
          <MultiSelect
            v-show="!isSelectChannel"
            v-model="selects.project"
            class="multiselect-sm"
            style="width: 250px"
            :options="projects"
            :placeholder="$t('table.selectProject')"
            label="name"
            track-by="id"
            @input="selectProject"
          ></MultiSelect>
          <MultiSelect
            v-show="!isSelectChannel"
            v-model="selects.order"
            class="multiselect-sm"
            style="width: 250px"
            :options="orders"
            :placeholder="$t('table.selectOrder')"
            label="name"
            track-by="id"
            :disabled="!(selects.project && selects.project.id)"
            @input="selectOrder"
          ></MultiSelect>
          <MultiSelect
            v-show="!isSelectChannel"
            v-model="selects.mediaPlan"
            class="multiselect-sm"
            style="width: 250px"
            :options="mediaPlans"
            :placeholder="$t('table.selectMediaPlan')"
            label="name"
            track-by="id"
            :disabled="!(selects.order && selects.order.id)"
            @input="selectMediaPlan"
          ></MultiSelect>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="broadcast-left" :class="[isThemeHeader === 'true' ? 'form-con__dark' : 'form-con', isSelectChannel ? 'w-0' : '']">
        <transition name="fade" mode="out-in">
          <div v-if="bookingStatus === 'loading'" class="wrapper-broadcast__loader" :class="isThemeHeader === 'true' ? 'form-con__dark' : 'form-con'">
            <SpinnerLoader :loading="bookingStatus" />
          </div>
        </transition>
        <div v-if="bookingStatus === 'success' && !isSelectChannel" class="table table-grid">
          <div class="table-header">
            <div class="table-header-text table-header-text-broadcast d-flex flex-wrap" style="flex-basis: 15%; padding-left: 5px; align-items: center">
              <div class="d-flex">
                <button class="btn-transparent mr-2" @click="getBookingInfo('prev', true, $event)">
                  <b-icon icon="arrow-left-square" font-scale="1.3"></b-icon>
                </button>
                <button class="btn-transparent" @click="getBookingInfo('next', true, $event)">
                  <b-icon icon="arrow-right-square" font-scale="1.3"></b-icon>
                </button>
              </div>
              <button class="btn-transparent mx-2" @click="toggleGridSize()">
                <b-icon
                  :icon="showSmallGrid ? 'zoom-in' : 'zoom-out'"
                  font-scale="1"
                  :title="showSmallGrid ? $t('table.zoomInGrid') : $t('table.zoomOutGrid')"
                ></b-icon>
              </button>
            </div>
            <div v-for="(colDate, index) in Object.keys(gridWithEmpty)" :key="index" class="table-header-text table-header-text-broadcast">
              {{ weekDays[new Date(colDate).getUTCDay()] }} {{ colDate | convertDate }}
              <span name="day-indicator" :class="getBroadcastingDisallowedEjectionTypeClasses(colDate)" :title="getClosedTypeTitle(colDate, 'booking')"></span>
              <b-icon v-if="checkIfDayClosed(colDate)" class="danger-color" icon="lock" :title="$t('alert.closeDay')"></b-icon>
              <div class="text-time-seconds">{{ Object.entries(countUsedTime)[index][1].total }}/{{ Object.entries(countUsedTime)[index][1].used }}</div>
            </div>
          </div>

          <div id="table-broadcast-grid" class="table-body table-body-broadcast" :class="showSmallGrid ? 'small-grid-mode' : ''">
            <table border class="w-100">
              <tr>
                <td style="width: 4%" :class="isThemeHeader === 'true' ? 'white-color' : 'text-color'">
                  <div id="time">
                    <template v-for="hour in scaleHour">
                      <div v-for="j in [0, 1, 2, 3, 4, 5]" :key="hour + '_' + j" class="time-hour" :style="{ height: max_scale / 6 + 'px' }">
                        {{ j === 0 ? String(hour).slice(0, 5) : '' }}
                      </div>
                    </template>
                  </div>
                </td>

                <td
                  v-for="(day, dIndex) in gridWithEmpty"
                  :key="dIndex"
                  style="width: 13.71%"
                  class="position-relative"
                  :class="{ 'closed-day': checkIfDayClosed(dIndex) }"
                >
                  <div
                    v-for="(program, pIndex) in day"
                    :key="dIndex + '_' + pIndex"
                    class="program"
                    :class="program.program_release_id && program.program_release_start_interval ? '' : 'independent-block'"
                    :style="[
                      program.marginTop ? { marginTop: `${program.marginTop}px` } : '',
                      program.paddingBottom ? { paddingBottom: `${program.paddingBottom}px` } : '',
                    ]"
                  >
                    <div v-if="program.program_release_id && program.program_release_start_interval" class="program_header">
                      {{ sec2time(program.program_release_start_interval) + ' - ' + sec2time(program.program_release_end_interval) }}
                      <b>{{ program.program_release_program_name }}</b> {{ program.program_release_name ? ' — ' + program.program_release_name : '' }}
                    </div>

                    <div
                      v-for="(block, bIndex) in program.blocks"
                      :key="dIndex + '_' + pIndex + '_' + bIndex"
                      class="block"
                      :class="[
                        setBlockBackground(block.block_duration, block.spot_total_duration),
                        setBlockBorder(block.block_type_id),
                        isThrottledByRequest || isThrottledByTimeout ? 'cursor-throttled' : 'cursor-pointer',
                      ]"
                      :style="block.marginTop ? { marginTop: `${block.marginTop}px` } : ''"
                      @click="addCommercial(block, dIndex)"
                      @contextmenu.prevent="showModalBlockSpots(block.block_id, dIndex, block.block_type_id, block.auction_step_coeff)"
                    >
                      {{ sec2time(block.block_start_interval) }}
                      <div class="top-right">
                        <div v-show="block.block_type_id == 2" class="auction-icon"><IconGavel width="15"></IconGavel></div>
                        <div v-show="block.is_fixed_price" class="fixed-price-block-icon"><IconCrown width="15"></IconCrown></div>
                      </div>

                      <div class="positions start">
                        <div :class="{ spot_busy: block.spot_positions.includes('1F') }"></div>
                        <div :class="{ spot_busy: block.spot_positions.includes('2F') }"></div>
                        <div :class="{ spot_busy: block.spot_positions.includes('3F') }"></div>
                      </div>
                      <p class="text-center mb-0 font-weight-bolder">{{ block.block_duration }}/{{ showBlockSecondParam(block, selects.mediaPlan) }}</p>
                      <p>
                        {{ getBlockRating(block.grps, grpByDays[dIndex], selects.measurements, selects.target) }}
                        /{{ getBlockFactRating(block.fact_grps, selects.measurements, selects.target) }}
                        <span>
                          {{
                            block.block_commercial_type_id === 1
                              ? 'CA'
                              : block.block_commercial_type_id === 2
                              ? 'SC'
                              : block.block_commercial_type_id === 3
                              ? 'RC'
                              : ''
                          }}
                        </span>
                      </p>
                      <div class="block-bottom-items" :class="block.is_in_project ? 'block-bottom-items__used' : 'bg-white'">Project</div>
                      <div class="block-bottom-items" :class="block.is_in_order ? 'block-bottom-items__used' : 'bg-white'">Order</div>
                      <div class="block-bottom-items" :class="block.is_in_mediaplan ? 'block-bottom-items__used' : 'bg-white'">MP</div>
                      <div class="positions end">
                        <div :class="{ spot_busy: block.spot_positions.includes('3L') }"></div>
                        <div :class="{ spot_busy: block.spot_positions.includes('2L') }"></div>
                        <div :class="{ spot_busy: block.spot_positions.includes('1L') }"></div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div
        v-if="isSelectChannel"
        class="broadcast-left d-flex justify-content-center align-items-center"
        :class="isThemeHeader === 'true' ? 'form-con__dark' : 'form-con'"
      >
        <div style="font-size: 18px" :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">{{ $t('broadcastSideBar.selectChannel') }}</div>
      </div>

      <div class="broadcast-right d-flex flex-column" :class="isThemeHeader === 'true' ? 'form-con__dark' : 'form-con '">
        <div v-if="modalEditMediaPlans && selects.mediaPlan && !isSelectChannel" class="flex-fill broadcast-right__inner-wrapper">
          <BlockSecondParamSelector v-model="secondBlockParam" />
          <h6 class="mt-2">
            <WarningClosedMP v-if="selects.mediaPlan.is_closed" />
            {{ $t('sideBar.commercials') }}
            <span class="small">({{ selects.mediaPlan.date_from | convertDate }} – {{ selects.mediaPlan.date_to | convertDate }})</span>
          </h6>
          <SelectableCommercialsTable
            ref="selectableTableWrapper"
            v-model="selectedCommercial"
            :media-plan-commercials.sync="commercialItems"
            :is-disabled="selects.mediaPlan.is_closed"
          />
          <div class="d-flex flex-wrap gap-2 justify-content-center mt-2">
            <b-button
              v-if="$checkPermissions('spot.replace_many')"
              v-b-modal.replace-spots
              class="sidebar-btn"
              size="sm"
              :disabled="selects.mediaPlan.is_closed"
              :title="selects.mediaPlan.is_closed ? $t('table.mpIsClosed') : ''"
            >
              {{ $t('table.replaceSpots') }}
            </b-button>
            <b-button
              v-if="$checkPermissions('spot.delete_many')"
              v-b-modal.delete-spots
              class="sidebar-btn"
              size="sm"
              :disabled="selects.mediaPlan.is_closed"
              :title="selects.mediaPlan.is_closed ? $t('table.mpIsClosed') : ''"
            >
              {{ $t('table.deleteSpots') }}
            </b-button>
            <b-button
              v-if="
                $checkPermissions('spot.fix_many') &&
                (canFixLowPriority ||
                  (!canFixLowPriority && selects.mediaPlan && (selects.mediaPlan.placement_type_id === 1 || selects.mediaPlan.placement_type_id === 2)))
              "
              v-b-modal.fix-spots
              class="sidebar-btn"
              size="sm"
              :disabled="selects.mediaPlan.is_closed"
              :title="selects.mediaPlan.is_closed ? $t('table.mpIsClosed') : ''"
            >
              {{ $t('table.fixSpots') }}
            </b-button>
            <b-button
              v-if="$checkPermissions('booking.channel_copy') && (!isAMA || (isAMA && booking && booking.meta && booking.meta.allow_mass_copy))"
              v-b-modal.copy-booking
              class="sidebar-btn"
              size="sm"
              :disabled="selects.mediaPlan.is_closed"
              :title="selects.mediaPlan.is_closed ? $t('table.mpIsClosed') : ''"
            >
              {{ $t('table.copyBookingShort') }}
            </b-button>
          </div>

          <WgrpTable :commercial-items="commercialItems" />
        </div>

        <div class="booking-sidebar-bottom-btns py-2 mt-auto">
          <router-link
            v-if="$checkPermissions('booking.channel_one_day')"
            :to="{
              name: 'booking-one-day',
              query: {
                agency_id: selects.project ? selects.project.agency_id : selects.agency ? selects.agency.id : agency_id || undefined,
                advertiser_id: selects.project ? selects.project.advertiser_id : selects.advertiser ? selects.advertiser.id : advertiser_id || undefined,
                project_id: selects.project ? selects.project.id : project_id || undefined,
                order_id: selects.order ? selects.order.id : order_id || undefined,
                mediaplan_id: selects.mediaPlan ? selects.mediaPlan.id : mediaplan_id || undefined,
                channel_id: selects.channels ? selects.channels.id : channel_id || undefined,
                date: selects.day ? selects.day : selects.mediaPlan ? selects.mediaPlan.date_from : date || undefined,
                measurement_id: selects.measurements ? selects.measurements.id : measurement_id || undefined,
                target_id: selects.target ? selects.target.id : target_id || undefined,
                commercial_type_id: selects.commercial ? selects.commercial.id : commercial_type_id || undefined,
                block_type_id: selects.block ? selects.block.id : block_type_id || undefined,
              },
            }"
            class="btn btn-sm btn-outline-danger"
          >
            {{ $t('broadcastSideBar.oneDay') }}
          </router-link>
          <b-button v-if="$checkPermissions('export.channel_booking')" size="sm" :disabled="exporting || isSelectChannel" @click="exportReport">
            {{ exporting ? $t('table.exporting') : $t('table.export') }}
          </b-button>
          <b-button size="sm" @click="$router.go(-1)">
            {{ $t('broadcastSideBar.exit') }}
          </b-button>
        </div>
      </div>
    </div>

    <ModalConfirmInQuery @confirmInQuery="confirmInQuery" />
    <ModalConfirmWithoutPremiumPosition @confirmWithoutPremium="confirmWithoutPremium" />

    <!-- MODAL BLOCK SPOTS -->
    <ModalBlockSpots
      :block-id="blockId"
      :closed-day="blockFromClosedDay"
      :block-type="blockType"
      :block-step="blockStep"
      layout="booking"
      @postSpot="getBookingInfo(lastUsed === 'day' ? 'day' : 'update')"
      @updateWgrp="loadWgrpTable"
    />
    <!-- MODAL BLOCK SPOTS -->

    <template v-if="selects.mediaPlan && modalEditMediaPlans">
      <ModalReplaceSpots
        :current-media-plan="modalEditMediaPlans"
        @updateWgrp="loadWgrpTable"
        @updateData="getBookingInfo(lastUsed === 'day' ? 'day' : 'update', true)"
      />
      <ModalDeleteSpots
        :current-media-plan="modalEditMediaPlans"
        @updateWgrp="loadWgrpTable"
        @updateData="getBookingInfo(lastUsed === 'day' ? 'day' : 'update', true)"
      />
      <ModalFixSpots :current-media-plan="modalEditMediaPlans" @updateData="getBookingInfo(lastUsed === 'day' ? 'day' : 'update', true)" />
      <ModalCopyBooking
        :current-media-plan="modalEditMediaPlans"
        @updateWgrp="loadWgrpTable"
        @updateData="getBookingInfo(lastUsed === 'day' ? 'day' : 'update', true)"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiSelect from '@/components/MultiSelect';
import errorsHandler from '@/utils/errorsHandler';
import SpinnerLoader from '../components/SpinnerLoader';
import convertDate from '../filters/convertDate';
import placementGrid from '../mixins/placementGrid';
import ModalBlockSpots from '@/components/Booking/ModalBlockSpots';
import ModalConfirmInQuery from '@/components/Booking/ModalConfirmInQuery';
import ModalConfirmWithoutPremiumPosition from '@/components/Booking/ModalConfirmWithoutPremiumPosition';
import downloadFileHandler from '@/mixins/downloadFileHandler';
import getBlockRatings from '@/mixins/getBlockRatings';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import showBlockSecondParam from '@/mixins/showBlockSecondParam';

export default {
  name: 'Booking',
  components: {
    SpinnerLoader,
    MultiSelect,
    ModalBlockSpots,
    DatepickerWrapper,
    ModalReplaceSpots: () => import('@/components/Booking/ModalReplaceSpots'),
    ModalDeleteSpots: () => import('@/components/Booking/ModalDeleteSpots'),
    ModalFixSpots: () => import('@/components/Booking/ModalFixSpots'),
    ModalCopyBooking: () => import('@/components/Booking/ModalCopyBooking'),
    ModalConfirmInQuery,
    ModalConfirmWithoutPremiumPosition,
    WgrpTable: () => import('@/components/Booking/WgrpTable'),
    SelectableCommercialsTable: () => import('@/components/Booking/SelectableCommercialsTable'),
    BlockSecondParamSelector: () => import('@/components/BlockSecondParamSelector'),
    WarningClosedMP: () => import('@/components/Booking/WarningClosedMP.vue'),
    IconGavel: () => import('@/components/icons/IconGavel.vue'),
    IconCrown: () => import('@/components/icons/IconCrown.vue'),
  },
  filters: {
    convertDate,
  },
  mixins: [placementGrid, downloadFileHandler, getBlockRatings, showBlockSecondParam],
  props: {
    channel_id: { type: [String, Number], default: undefined },
    date: { type: String, default: undefined },
    agency_id: { type: [String, Number], default: undefined },
    advertiser_id: { type: [String, Number], default: undefined },
    project_id: { type: [String, Number], default: undefined },
    order_id: { type: [String, Number], default: undefined },
    mediaplan_id: { type: [String, Number], default: undefined },
    measurement_id: { type: [String, Number], default: undefined },
    target_id: { type: [String, Number], default: undefined },
    commercial_type_id: { type: [String, Number], default: undefined },
    block_type_id: { type: [String, Number], default: undefined },
  },
  data() {
    return {
      lastDay: '',
      isSelectChannel: true,
      selects: {
        commercial: '',
        block: '',
        channels: '',
        measurements: '',
        target: '',
        day: '',
        agency: '',
        advertiser: '',
        project: '',
        order: '',
        mediaPlan: '',
      },
      premiumPositionOptions: [
        { text: '1F', value: '1F' },
        { text: '2F', value: '2F' },
        { text: '3F', value: '3F' },
        { text: '--', value: null },
        { text: '3L', value: '3L' },
        { text: '2L', value: '2L' },
        { text: '1L', value: '1L' },
      ],
      selectedCommercial: null,
      commercialItems: [],
      currentBlock: '',
      lastUsed: '',
      blockId: undefined,
      blockFromClosedDay: false,
      blockType: undefined,
      blockStep: undefined,
      exporting: false,
      isPartialUpdate: '',
      updateGridTimeout: parseInt(process.env.VUE_APP_DELAYED_UPDATE_TIMEOUT) || 0,
      updateGridTimeoutId: null,
      isThrottledByRequest: false,
      isThrottledByTimeout: false,
      throttleTimeout: parseInt(process.env.VUE_APP_THROTTLE_SPOT_PLACEMENT) || 0,
      throttleTimeoutId: null,
    };
  },
  validations: {},
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      booking: 'getBooking',
      bookingStatus: 'getBookingStatus',
      isThemeHeader: 'isTheme',
      target: 'getTarget',
      channel: 'getChannel',
      measurementsList: 'getMeasurementsList',
      targetList: 'getTargetList',
      agenciesListWithAll: 'getAgenciesListWithAll',
      advertisersListWithAll: 'getAdvertisersListWithAll',
      projects: 'getProjectsList',
      orders: 'getOrdersList',
      mediaPlans: 'getMediaPlansList',
      modalEditMediaPlans: 'getModalEditMediaPlans',
      commercialVersionTypes: 'getCommercialVersionTypes',
      commercialType: 'getCommercialType',
      blockTypes: 'getBlockTypes',
      spots: 'getBlockSpotsList',
      placementType: 'getPlacementType',
      isAMA: 'getIsCurrentUserAgencyManager',
      selectedSaleshouse: 'getSelectedSaleshouse',
      sh_settings: 'getSalehousesSettings',
    }),

    canCreate: function () {
      return this.$checkPermissions('spot.create');
    },
    canDelete: function () {
      return this.$checkPermissions('spot.delete');
    },
    canReadSpotList: function () {
      return this.$checkPermissions('booking.block');
    },
    canWorkInClosedDay: function () {
      return this.$checkPermissions('channel.operations_closed_days');
    },
    canFixLowPriority: function () {
      return this.$checkPermissions('spot.fix_low_priority');
    },
  },
  watch: {
    // Filters
    async 'selects.measurements'() {
      if (!this.isSelectChannel) {
        this.selects.target = '';
        if (this.selects.measurements) {
          await this.$store.dispatch('GET_TARGET', {
            'filter[measurement_company_id]': this.selects.measurements.id,
          });
        }
      }
    },
    bookingStatus() {
      if (this.bookingStatus === 'success') {
        this.isSelectChannel = false;
      }
    },
    async 'selects.day'() {
      if (!this.isSelectChannel) {
        await this.getBookingInfo('day', true);
        this.lastUsed = 'day';
      }
    },
    async 'selects.project'() {},

    async 'selects.commercial'() {
      if (!this.isSelectChannel) await this.getBookingInfo(this.lastUsed === 'day' ? 'day' : 'update', true);
    },
    async 'selects.block'() {
      if (!this.isSelectChannel) await this.getBookingInfo(this.lastUsed === 'day' ? 'day' : 'update', true);
    },
    // Load n models for selects in every row
    'modalEditMediaPlans.commercials'() {
      this.commercialItems.splice(0); // clear list on updates
      if (this.modalEditMediaPlans.commercials && this.commercialVersionTypes) {
        this.modalEditMediaPlans.commercials.forEach((el) => {
          this.commercialItems.push({
            id: el.id,
            name: el.name,
            duration: el.duration,
            version: this.commercialVersionTypes.data.find((type) => type.id === el.commercial_version_type_id)?.name,
            selected: null,
            distance: el.distance,
            double_spot_id: el.double_spot_id,
            co_brand_id: el.co_brand_id,
          });
        });
      }
    },
  },
  created() {
    document.title = this.$i18n.t('table.booking') + ' – OpenMediaLogic';
  },
  async mounted() {
    await this.loadProps();
  },
  updated() {
    this.$nextTick(function () {
      if (this.bookingStatus === 'success' && !this.isSelectChannel && this.posY >= 10) {
        // restore scroll pos after some action
        const broadcastTable = document.getElementById('table-broadcast-grid');
        const TABLE_Y_POS = broadcastTable.getBoundingClientRect().top;
        broadcastTable.scrollTo({ top: this.posY - this.clientY + TABLE_Y_POS });
        this.posY = 0;
        this.clientY = 0;
      }
    });
  },

  destroyed() {
    this.$store.commit('clearAdvertisersList');
    this.$store.commit('clearProjects');
    this.$store.commit('clearOrders');
    this.$store.commit('clearMediaPlans');
    this.$store.commit('clearTargetsList');
    this.$store.commit('clearBooking');
  },
  methods: {
    async loadProps() {
      await this.getSelectInfo();
      if (this.date && /\d{4}-\d{2}-\d{2}/.test(this.date)) {
        this.selects.day = this.date;
        this.lastUsed = 'day';
      }
      //set first date of week
      this.setCountAndDates(this.lastUsed === 'day' ? 'day' : 'update');
      this.getFirstAndLastGridYear(this.lastUsed === 'day' ? this.firstDays : this.firstDay);
      this.prevYearsOfGrid = this.yearsOfGrid;

      if (this.agency_id && /^\d+$/.test(this.agency_id)) {
        this.selects.agency = this.agenciesListWithAll.find((el) => el.id === +this.agency_id) || '';
        if (this.selects.agency) {
          await this.$store.dispatch('GET_ADVERTISERS', { per_page: 1000, 'filter[agency_id]': this.selects.agency ? this.selects.agency.id : null });

          if (this.advertiser_id && /^\d+$/.test(this.advertiser_id)) {
            this.selects.advertiser = this.advertisersListWithAll.find((el) => el.id === +this.advertiser_id) || '';
          }
          await this.$store.dispatch('GET_PROJECTS', {
            per_page: 1000,
            'filter[agency_id]': this.selects.agency?.id,
            'filter[advertiser_id]': this.selects.advertiser?.id,
            'filter[year_id]': this.yearsOfGrid || undefined,
            include: 'advertiser',
          });

          if (this.project_id && /^\d+$/.test(this.project_id)) {
            this.selects.project = this.projects.find((el) => el.id === +this.project_id) || '';
            if (this.selects.project) {
              await this.$store.dispatch('GET_ORDERS', {
                'filter[project_id]': this.selects.project.id,
                per_page: 1000,
              });
              if (this.order_id && /^\d+$/.test(this.order_id)) {
                this.selects.order = this.orders.find((el) => el.id === +this.order_id) || '';
                if (this.selects.order) {
                  await this.$store.dispatch('GET_MEDIA_PLANS', {
                    'filter[order_id]': this.selects.order.id,
                    'filter[channel_id]': this.channel_id && /^\d+$/.test(this.channel_id) ? this.channel_id : undefined,
                    //include: "commercials",
                    per_page: 1000,
                  });

                  if (this.mediaplan_id && /^\d+$/.test(this.mediaplan_id)) {
                    this.selects.mediaPlan = this.mediaPlans.find((el) => el.id === +this.mediaplan_id) || '';
                    if (this.selects.mediaPlan) {
                      Promise.all([
                        this.$store.dispatch('GET_MEDIA_PLANS_ID', {
                          id: this.selects.mediaPlan.id,
                          data: {
                            include: 'commercials',
                            per_page: 1000,
                          },
                        }),
                        this.$store.dispatch('GET_MEDIA_PLANS_WGRP_TABLE', {
                          id: this.selects.mediaPlan.id,
                          data: {},
                        }),
                      ]);
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (this.commercial_type_id && /^\d+$/.test(this.commercial_type_id)) {
        this.selects.commercial = this.commercialType.find((el) => el.id === +this.commercial_type_id);
      }

      if (this.block_type_id && /^\d+$/.test(this.block_type_id)) {
        this.selects.block = this.blockTypes.find((el) => el.id === +this.block_type_id);
      }

      if (this.measurement_id && /^\d+$/.test(this.measurement_id)) {
        this.selects.measurements = this.measurementsList.find((el) => el.id === +this.measurement_id);
        if (this.selects.measurements) {
          await this.$store.dispatch('GET_TARGET', {
            'filter[measurement_company_id]': this.selects.measurements.id,
          });
          if (this.target_id && /^\d+$/.test(this.target_id)) {
            this.selects.target = this.targetList.find((el) => el.id === +this.target_id);
          }
        }
      } else if (this.target_id && /^\d+$/.test(this.target_id)) {
        await this.$store.dispatch('GET_TARGET', {});
        this.selects.target = this.targetList?.find((el) => el.id === +this.target_id);
        this.selects.measurements = this.measurementsList.find((el) => el.id === +this.selects.target?.measurement_company_id);
        if (this.selects.measurements)
          await this.$store.dispatch('GET_TARGET', {
            'filter[measurement_company_id]': this.selects.measurements.id,
          });
      }

      if (this.channel_id && /^\d+$/.test(this.channel_id)) {
        this.selects.channels = this.channel.find((el) => el.id === +this.channel_id);
        if (this.selects.channels) {
          this.count = 0;
          await this.getBookingInfo(this.lastUsed === 'day' ? 'day' : 'update', true);
        }
      }
    },

    async getBookingInfo(date, loader, clickPos) {
      if (!loader) this.isPartialUpdate = 'loading';
      this.setCountAndDates(date);
      this.updateQuery();
      if (clickPos) this.getPosY(clickPos);
      if (loader) this.$store.commit('getBookingRequest');
      await this.$store.dispatch('GET_BOOKING', {
        data: {
          channel: this.selects.channels.id,
        },
        date: {
          date_start_at: date === 'day' ? this.firstDays : this.firstDay,
          date_end_at: date === 'day' ? this.lastDays : this.lastDay,
          commercial_type_id: this.selects.commercial.id || null,
          block_type_id: this.selects.block.id || null,
          project_id: this.selects.project ? this.selects.project.id : null,
          order_id: this.selects.order ? this.selects.order.id : null,
          mediaplan_id: this.selects.mediaPlan ? this.selects.mediaPlan.id : null,
        },
      });
      await this.buildGrid();

      //check for changes in years of grid to reload Projects list if true
      this.getFirstAndLastGridYear(date === 'day' ? this.firstDays : this.firstDay);

      if (this.prevYearsOfGrid !== this.yearsOfGrid) {
        if (this.selects.agency) {
          await this.$store.dispatch('GET_PROJECTS', {
            per_page: 1000,
            'filter[agency_id]': this.selects.agency?.id,
            'filter[advertiser_id]': this.selects.advertiser?.id,
            'filter[year_id]': this.yearsOfGrid || undefined,
            include: 'advertiser',
          });
          //clear POM selection if selected project isn't available in current list
          if (!this.yearsOfGrid.includes(this.selects.project?.year_id)) {
            this.selects.mediaPlan = '';
            this.selectedCommercial = null;
            this.commercialItems = [];
            this.$store.commit('clearWGRPtable');
            this.selects.order = '';
            this.selects.project = '';
            this.$store.commit('clearOrders');
            this.$store.commit('clearMediaPlans');
          }
        }
      }
      this.prevYearsOfGrid = this.yearsOfGrid;
      this.isPartialUpdate = '';
    },

    async getSelectInfo() {
      await Promise.all([
        this.measurementsList.length < 1 ? this.$store.dispatch('GET_MEASUREMENTS', { per_page: 1000 }) : undefined,
        this.channel.length < 1 ? this.$store.dispatch('GET_CHANNEL', { per_page: 1000 }) : undefined,
        this.commercialType.length < 1 ? this.$store.dispatch('GET_COMMERCIAL_TYPE') : undefined,
        this.blockTypes.length < 1 ? this.$store.dispatch('GET_BLOCK_TYPES') : undefined,
        this.agenciesListWithAll.length < 1 ? this.$store.dispatch('GET_AGENCIES', { per_page: 1000 }) : undefined,
        !(this.commercialVersionTypes && this.commercialVersionTypes.length > 0) ? this.$store.dispatch('GET_COMM_VER_TYPES', { per_page: 1000 }) : undefined,
        this.placementType.length < 1 ? this.$store.dispatch('GET_PLACEMENT_TYPE', { per_page: 1000 }) : undefined,
      ]);
    },

    async selectChannel() {
      this.count = 0;
      await this.getBookingInfo(this.lastUsed === 'day' ? 'day' : 'update', true);
      if (this.selects.order) {
        if (this.selects.mediaPlan && this.selects.mediaPlan.channel_id !== this.selects.channels?.id) {
          this.selects.mediaPlan = '';
          this.selectedCommercial = null;
          this.commercialItems = [];
          this.$store.commit('clearWGRPtable');
        }
        await this.$store.dispatch('GET_MEDIA_PLANS', {
          'filter[order_id]': this.selects.order.id,
          'filter[channel_id]': this.selects.channels?.id,
          per_page: 1000,
        });
      }
    },

    async loadWgrpTable() {
      //this.$store.commit('clearWGRPtable');

      if (this.selects.mediaPlan) {
        let year = this.selects.mediaPlan.date_from.substr(0, 4);
        if (this.FEATURES.EQUALS) {
          if (year && !(this.sh_settings && this.sh_settings[0]?.year_id == year && this.selectedSaleshouse.id == this.sh_settings.saleshouse_id))
            this.$store.dispatch('GET_SALEHOUSES_SETTINGS', {
              //    id: 123,
              'filter[saleshouse_id]': this.selectedSaleshouse.id,
              'filter[year_id]': year,
            });
        }

        await this.$store.dispatch('GET_MEDIA_PLANS_WGRP_TABLE', {
          id: this.selects.mediaPlan.id,
          data: {},
        });
      }
    },

    updateQuery() {
      const date = this.lastUsed === 'day' ? this.selects.day : this.firstDay;
      if (
        this.$route.query.date != date ||
        this.$route.query.channel_id != this.selects.channels?.id ||
        this.$route.query.agency_id != this.selects.agency?.id ||
        this.$route.query.advertiser_id != this.selects.advertiser?.id ||
        this.$route.query.project_id != this.selects.project?.id ||
        this.$route.query.order_id != this.selects.order?.id ||
        this.$route.query.mediaplan_id != this.selects.mediaPlan?.id ||
        this.$route.query.measurement_id != this.selects.measurements?.id ||
        this.$route.query.target_id != this.selects.target?.id ||
        this.$route.query.commercial_type_id != this.selects.commercial?.id ||
        this.$route.query.block_type_id != this.selects.block?.id
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            date: date,
            channel_id: this.selects.channels.id,
            agency_id: this.selects.agency?.id,
            advertiser_id: this.selects.advertiser?.id,
            project_id: this.selects.project?.id,
            order_id: this.selects.order?.id,
            mediaplan_id: this.selects.mediaPlan?.id,
            measurement_id: this.selects.measurements?.id,
            target_id: this.selects.target?.id,
            commercial_type_id: this.selects.commercial?.id,
            block_type_id: this.selects.block?.id,
          },
        });
      }
    },

    async delayedGridUpdate() {
      if (this.updateGridTimeoutId) {
        clearTimeout(this.updateGridTimeoutId);
      }
      this.updateGridTimeoutId = setTimeout(() => {
        this.getBookingInfo(this.lastUsed === 'day' ? 'day' : 'update');
        this.updateGridTimeoutId = null;
      }, this.updateGridTimeout);
    },

    async throttleAddCommercial() {
      if (this.throttleTimeoutId) {
        clearTimeout(this.throttleTimeoutId);
      }
      this.isThrottledByTimeout = true;
      this.throttleTimeoutId = setTimeout(() => {
        this.isThrottledByTimeout = false;
      }, this.throttleTimeout);
    },

    async addCommercial(block, prop_day) {
      if (this.isThrottledByRequest || this.isThrottledByTimeout) return;
      if (!this.canCreate) return;
      const dayBroadcastingSettings = this.gridBroadcastingSettings.find((e) => e.date === prop_day);
      // set closed state if Current User is AMA and day is closed for AMA or the same for Manager SH.
      const closedDay =
        (((dayBroadcastingSettings.is_closed & 1) === 1 && this.isCurrentUserAMA) || ((dayBroadcastingSettings.is_closed & 2) === 2 && this.isCurrentUserSH)) &&
        !this.canWorkInClosedDay;

      if (closedDay) {
        this.$notify({
          type: 'error',
          title: this.$i18n.t('alert.closeDay'),
          duration: 3000,
        });
      } else {
        if (this.selectedCommercial) {
          //this.getPosY(event);
          //const timeOfClick = this.timeOfClick(this.posY);
          const blockId = block.block_id;
          if (this.selectedCommercial.double_spot_id) {
            const formData = {
              double_spot_id: this.selectedCommercial.double_spot_id,
              position: this.selectedCommercial.selected,
              mediaplan_id: this.selects.mediaPlan.id,
            };
            await this.$store.dispatch('POST_DOUBLE_SPOT_IN_BLOCKS', {
              blockId,
              formData,
              handler: (res) => {
                if (res.data.warnings) {
                  this.$notify({
                    type: 'warning',
                    duration: 5000,
                    title: this.$i18n.t('alert.addCommercials'),
                    text: this.$i18n.t('alert.warning') + ': ' + res.data.warnings[0] + ', ' + res.data.warnings[1],
                  });
                } else {
                  this.$notify({
                    type: 'success',
                    title: this.$i18n.t('alert.addCommercials'),
                  });
                }
                this.getBookingInfo(this.lastUsed === 'day' ? 'day' : 'update');
                this.loadWgrpTable();
              },
              handlerError: (errors) => {
                errorsHandler(errors, this.$notify);
              },
            });
          } else {
            const formData = {
              commercial_id: this.selectedCommercial.id,
              position: this.selectedCommercial.selected,
              mediaplan_id: this.selects.mediaPlan.id,
            };
            if (this.throttleTimeout > 0) {
              this.isThrottledByRequest = true;
              await this.throttleAddCommercial();
            }
            await this.$store.dispatch('POST_COMMERCIAL_IN_BLOCKS', {
              blockId,
              formData,
              handler: async (res) => {
                if (res.data.warnings) {
                  this.$notify({
                    type: 'warning',
                    duration: 5000,
                    title: this.$i18n.t('alert.addCommercials'),
                    text: this.$i18n.t('alert.warning') + ': ' + res.data.warnings[0] + ', ' + res.data.warnings[1],
                  });
                } else {
                  this.$notify({
                    type: 'success',
                    title: this.$i18n.t('alert.addCommercials'),
                  });
                }
                await this.buildGrid(res.data);
                if (this.updateGridTimeout > 0) {
                  await this.delayedGridUpdate();
                } else {
                  this.getBookingInfo(this.lastUsed === 'day' ? 'day' : 'update');
                }
                this.loadWgrpTable();
                this.isThrottledByRequest = false;
              },
              handlerError: (errors) => {
                if (errors.data && errors.data.errors && errors.data.errors.duration) {
                  // Not enough free time. Put in query?
                  this.currentBlock = block;
                  this.showModalConfirmInQuery();
                } else if (errors.data && errors.data.errors && errors.data.errors.position) {
                  // This prem. position has been already taken. Put in other free place\query?
                  this.currentBlock = block;
                  this.showModalConfirmWithoutPremium();
                } else {
                  errorsHandler(errors, this.$notify);
                }
                this.isThrottledByRequest = false;
              },
            });
          }
        } else {
          this.$notify({
            type: 'error',
            title: this.$i18n.t('alert.selectCommercialFirst'),
            duration: 3000,
          });
        }
      }
    },

    showModalConfirmInQuery() {
      this.$bvModal.show('confirm-in-query');
    },
    hideModalConfirmInQuery() {
      this.$bvModal.hide('confirm-in-query');
    },

    // Add commercial in query
    async confirmInQuery() {
      if (this.isThrottledByRequest || this.isThrottledByTimeout) return;
      const blockId = this.currentBlock.block_id;
      const formData = {
        commercial_id: this.selectedCommercial.id,
        position: this.selectedCommercial.selected,
        mediaplan_id: this.selects.mediaPlan.id,
        force: true,
      };
      if (this.throttleTimeout > 0) {
        this.isThrottledByRequest = true;
        await this.throttleAddCommercial();
      }
      await this.$store.dispatch('POST_COMMERCIAL_IN_BLOCKS', {
        blockId,
        formData,
        handler: async ({ data }) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.addCommercialInQueue'),
          });
          this.currentBlock = '';
          await this.buildGrid(data);
          if (this.updateGridTimeout > 0) {
            await this.delayedGridUpdate();
          } else {
            this.getBookingInfo(this.lastUsed === 'day' ? 'day' : 'update');
          }
          this.loadWgrpTable();
          this.isThrottledByRequest = false;
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
          this.isThrottledByRequest = false;
        },
      });
      this.hideModalConfirmInQuery();
    },

    showModalConfirmWithoutPremium() {
      this.$bvModal.show('confirm-without-premium');
    },
    hideModalConfirmWithoutPremium() {
      this.$bvModal.hide('confirm-without-premium');
    },

    // Add commercial without premium position in query
    async confirmWithoutPremium() {
      if (this.isThrottledByRequest || this.isThrottledByTimeout) return;
      const blockId = this.currentBlock.block_id;
      const formData = {
        commercial_id: this.selectedCommercial.id,
        //position: this.selectedCommercial.selected,
        mediaplan_id: this.selects.mediaPlan.id,
        force: true,
      };
      if (this.throttleTimeout > 0) {
        this.isThrottledByRequest = true;
        await this.throttleAddCommercial();
      }
      await this.$store.dispatch('POST_COMMERCIAL_IN_BLOCKS', {
        blockId,
        formData,
        handler: async ({ data }) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.addCommercialInQueue'),
          });
          //this.$refs.selectableTable.clearSelected();
          this.currentBlock = '';
          await this.buildGrid(data);
          if (this.updateGridTimeout > 0) {
            await this.delayedGridUpdate();
          } else {
            this.getBookingInfo(this.lastUsed === 'day' ? 'day' : 'update');
          }
          this.loadWgrpTable();
          this.isThrottledByRequest = false;
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
          this.isThrottledByRequest = false;
        },
      });
      this.hideModalConfirmWithoutPremium();
    },

    //Spots in block
    async showModalBlockSpots(block_id, prop_day, block_type, block_step) {
      if (!this.canReadSpotList) return;
      const dayBroadcastingSettings = this.gridBroadcastingSettings.find((e) => e.date === prop_day);
      await this.$store.dispatch('GET_BLOCK_SPOTS', block_id);
      this.blockId = block_id;
      this.blockType = block_type;
      this.blockStep = block_step;
      // set closed state if Current User is AMA and day is closed for AMA or the same for Manager SH.
      this.blockFromClosedDay =
        (((dayBroadcastingSettings.is_closed & 1) === 1 && this.isCurrentUserAMA) || ((dayBroadcastingSettings.is_closed & 2) === 2 && this.isCurrentUserSH)) &&
        !this.canWorkInClosedDay;
      this.$bvModal.show('block-spots-list');
    },

    async exportReport() {
      this.exporting = true;
      await this.$store.dispatch('GET_EXPORT_BOOKING', {
        id: this.selects.channels.id,
        params: {
          format: 'xlsx',
          date_start_at: this.lastUsed === 'day' ? this.firstDays : this.firstDay,
          commercial_type_id: this.selects.commercial?.id,
          block_type_id: this.selects.block?.id,
          project_id: this.selects.project?.id,
          order_id: this.selects.order?.id,
          mediaplan_id: this.selects.mediaPlan?.id,
        },
        handler: (res) => {
          this.prepareAndDownloadFile(res);
        },
      });
      this.exporting = false;
    },

    // Commercials filters
    async selectAgency() {
      if (this.isSelectChannel) return;
      this.selects.advertiser = '';
      this.selects.project = '';
      this.selects.order = '';
      this.selects.mediaPlan = '';
      this.selectedCommercial = null;
      this.commercialItems = [];
      this.$store.commit('clearWGRPtable');
      await Promise.all([
        this.selects.agency?.id
          ? this.$store.dispatch('GET_ADVERTISERS', { per_page: 1000, 'filter[agency_id]': this.selects.agency ? this.selects.agency.id : null })
          : undefined,
        this.$store.dispatch('GET_PROJECTS', {
          per_page: 1000,
          'filter[agency_id]': this.selects.agency?.id,
          'filter[year_id]': this.yearsOfGrid,
          include: 'advertiser',
        }),
      ]);
      this.updateQuery();
    },
    async selectAdvertiser() {
      if (!this.isSelectChannel) {
        this.selects.project = '';
        this.selects.order = '';
        this.selects.mediaPlan = '';
        this.selectedCommercial = null;
        this.commercialItems = [];
        this.$store.commit('clearWGRPtable');
        await this.$store.dispatch('GET_PROJECTS', {
          per_page: 1000,
          'filter[agency_id]': this.selects.agency?.id,
          'filter[advertiser_id]': this.selects.advertiser ? this.selects.advertiser.id : null,
          'filter[year_id]': this.yearsOfGrid,
          include: 'advertiser',
        });
        this.updateQuery();
      }
    },
    async selectProject() {
      if (!this.isSelectChannel) {
        //this.$store.commit('getBookingRequest');
        this.selects.order = '';
        this.selects.mediaPlan = '';
        this.selectedCommercial = null;
        this.commercialItems = [];
        this.$store.commit('clearWGRPtable');
        this.getPosY({ clientY: 0 });
        if (this.selects.project) {
          await this.$store.dispatch('GET_ORDERS', {
            'filter[project_id]': this.selects.project.id,
            per_page: 1000,
          });
        }
        await this.getBookingInfo(this.lastUsed === 'day' ? 'day' : 'update');
      }
    },
    async selectOrder() {
      if (!this.isSelectChannel) {
        //this.$store.commit('getBookingRequest');
        this.selects.mediaPlan = '';
        this.selectedCommercial = null;
        this.commercialItems = [];
        this.$store.commit('clearWGRPtable');
        this.getPosY({ clientY: 0 });
        if (this.selects.order) {
          await this.$store.dispatch('GET_MEDIA_PLANS', {
            'filter[order_id]': this.selects.order.id,
            'filter[channel_id]': this.selects.channels?.id,
            //include: "commercials",
            per_page: 1000,
          });
        }
        await this.getBookingInfo(this.lastUsed === 'day' ? 'day' : 'update');
      }
    },
    async selectMediaPlan() {
      if (!this.isSelectChannel) {
        //this.$store.commit('getBookingRequest');
        this.commercialItems = [];
        this.selectedCommercial = null;
        this.getPosY({ clientY: 0 });
        this.$store.commit('clearWGRPtable');
        if (this.selects.mediaPlan) {
          await this.$store.dispatch('GET_MEDIA_PLANS_ID', {
            id: this.selects.mediaPlan.id,
            data: {
              include: 'commercials',
              per_page: 1000,
            },
          });
          await this.loadWgrpTable();
        }
        await this.getBookingInfo(this.lastUsed === 'day' ? 'day' : 'update');
      }
    },
  },
};
</script>

<style lang="sass">
@use "~/src/assets/sass/broadcast.sass"

.booking-page
  .broadcast-left
    height: calc(100vh - 145px)
    width: 75%

  .broadcast-right
    width: 25%
    padding-left: 8px
    padding-right: 8px

  .broadcast-right__inner-wrapper
    overflow: auto
    max-height: calc(100vh - 198px)

  .table-body-broadcast
    height: calc(100vh - 204px)

  .wrapper-broadcast__loader
    height: calc(100vh - 144px) !important
    width: 75% !important
    top: 144px

  .commercial-list__wrapper
    max-height: calc((100vh - 380px) / 2)
    overflow-y: auto
    .commercials-table
      min-width: 350px

  .form-con__dark .commercial-list__wrapper
    color: #a1a8b8!important

  .block-bottom-items__used
    background-color: #b7ed99 !important

  .bg-dark-lighter
    background-color: #383e4c !important

  .btn.sidebar-btn
    width: 48%
    font-size: 12px
    max-width: 170px
    padding: 4px 1px

@media(min-width: 1440px)
  .booking-page
    .broadcast-left
      width: 70%

    .broadcast-right
      width: 30%

    .wrapper-broadcast__loader
      width: 70% !important
</style>
